// extracted by mini-css-extract-plugin
export var Bold = "MentionsLegales-module--Bold--XtxoP";
export var ContactLink = "MentionsLegales-module--ContactLink--+3HRU";
export var Contactlink = "MentionsLegales-module--Contactlink--P3Zpb";
export var DevelopmentWrapper = "MentionsLegales-module--DevelopmentWrapper--7saRZ";
export var LegalMentionsWrapper = "MentionsLegales-module--LegalMentionsWrapper--aFuGP";
export var Link = "MentionsLegales-module--Link--nrPwW";
export var NameValueWrapper = "MentionsLegales-module--NameValueWrapper--k6R8x";
export var RowWrapper = "MentionsLegales-module--RowWrapper--OsC7P";
export var Wrapper = "MentionsLegales-module--Wrapper--gWHGe";
export var link = "MentionsLegales-module--link--wo1kn";