import * as React from "react";
import {
  Wrapper,
  LegalMentionsWrapper,
  NameValueWrapper,
  RowWrapper,
  Bold,
  ContactLink,
} from "./MentionsLegales.module.css";
import { GrayTitle } from "../../../css/Title.module.css";

const MentionsLegales = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>
        MENTIONS LÉGALES
      </h1>
      <div className={LegalMentionsWrapper}>
        <div>
            <p style={{marginTop:"20px", fontWeight:"bolder"}}>Informations légales</p>
            <br />
            <p style={{fontWeight:"bolder"}}>I. CONDITIONS JURIDIQUES</p>
            <p>Le présent site www.ghostgalerie.com est édité par FAA, société par actions simplifiée dont le capital social est fixé à 501 527,00 € euros, immatriculée au registre du commerce et des sociétés de Marseille sous le numéro 83035459300014, dont le siège social est situé 10 rue Stanislas torrents 13006 Marseille - email : contact@ghostgalerie.com</p>
            <p>Directeurs de publication : Stéphane Miquel.</p>
            <p>L'accès au site et l'utilisation de son contenu sont soumis aux conditions d'utilisation prévues ci-après. Le fait d'accéder et de naviguer sur ce Site implique l'acceptation entière et sans réserve par l'internaute des stipulations suivantes.</p>
            <br /><br />
            <p style={{fontWeight:"bolder"}}>II. PROPRIÉTÉ INTELLECTUELLE</p>
            <p style={{fontWeight:"bolder"}}>II.1 Propriété</p>
            <p>L'ensemble du Site est la propriété de la Galerie.</p>
            <br/>
            <p style={{fontWeight:"bolder"}}>II.2. Sanctions</p>
            <p>L'utilisation de tout ou partie du Site, notamment par téléchargement, reproduction, transmission, représentation ou diffusion, à d'autres fins qu'un usage personnel et privé et dans un but non commercial par l'internaute est strictement interdite. Le contrevenant s'expose aux sanctions prévues tant par le Code de la Propriété Intellectuelle en matière de contrefaçon (article L. 335-1 et suivants) et de droit des marques (article L. 716-1 et suivants) notamment, que par le Code Civil en matière de responsabilité civile générale (article 9, alinéas 1382 et suivants).</p>
            <br />
            <p style={{fontWeight:"bolder"}}>II.3 Liens hypertextes</p>
            <p>Il est interdit de créer tout lien hypertexte vers l'une quelconque des pages web ou l'un quelconque des éléments du Site sans l'autorisation écrite préalable de la Galerie. Cette autorisation peut être retirée à tout moment. Tous les sites ayant un lien hypertexte avec le Site ou l'un de ses éléments ne sont pas sous le contrôle de la Galerie. La Galerie décline formellement toute responsabilité (notamment éditoriale) concernant l'accès et le contenu de ces sites.</p>
            <br /><br />
            <p style={{fontWeight:"bolder"}}>III. DONNÉES PERSONNELLES</p>
            <p>Le terme " données personnelles " désigne l'ensemble des informations personnelles (notamment le nom d'utilisateur, le mot de passe, le nom, le prénom, la date de naissance, l'adresse électronique et l'adresse postale) que l'internaute est susceptible de communiquer à la Galerie dans le cadre de son inscription à la newsletter de la Galerie via la rubrique " NEWSLETTER " du Site et/ou pour tout message envoyé via le formulaire de contact du Site. Ces données, quelle que soit leur nature, permettent directement ou indirectement à la Galerie d'identifier et de mieux connaître les internautes et de leur adresser sa newsletter et/ou de répondre à leurs messages. Lorsque l'internaute divulgue des données personnelles, il répond aux questions posées lors du processus d'inscription à la newsletter de la Galerie et communique ainsi des informations précises, qui ne portent pas atteinte aux intérêts ou aux droits des tiers.</p>
            <br />
            <p style={{fontWeight:"bolder"}}>III.2 Consentement</p>
            <p>Aucune donnée à caractère personnel ne sera collectée sans le consentement personnel de l'internaute concerné. Le caractère facultatif ou obligatoire des éléments qui seront communiqués à la Galerie à cette fin sera préalablement indiqué aux internautes. Lesdits internautes ne sont en aucun cas tenus de communiquer des données personnelles à la Galerie. Toutefois, en cas de refus de l'internaute, la Galerie ne sera pas en mesure d'envoyer la newsletter à l'internaute concerné et/ou de répondre à ses messages. En tout état de cause, si l'internaute ne souhaite pas ou plus recevoir la newsletter de la Galerie, ledit internaute pourra en informer la Galerie en envoyant un email à l'adresse suivante : info@ghostgalerie.com. En tout état de cause, l'internaute aura également la possibilité de résilier son abonnement à la newsletter de la Galerie en cliquant sur une encre hypertexte incluse directement dans chaque numéro de ladite newsletter adressée à l'internaute en question.</p>
            <br />
            <p style={{fontWeight:"bolder"}}>III.3 Identité du responsable du traitement des données personnelles</p>
            <p>GHOST galerie est responsable de la collecte et du traitement des données personnelles du Site.</p>
            <br />
            <p style={{fontWeight:"bolder"}}>III.4 Destinataire(s) des données personnelles</p>
            <p>La Galerie est le seul destinataire des données personnelles collectées sur le Site. Les données personnelles ne sont pas communiquées à des tiers sauf accord préalable pour le partage de ces informations et si la galerie est sommée par une autorité judiciaire ou toute autre autorité administrative de lui communiquer les informations.</p>
            <br />
            <p style={{fontWeight:"bolder"}}>III.5 Droits de l'internaute</p>
            <p>Chaque internaute peut exercer son droit d'accès, de rectification, de complément, de mise à jour, de verrouillage, de suppression des données personnelles le concernant et, pour des motifs légitimes, s'opposer à leur traitement. Pour exercer les droits ci-dessus, l'internaute peut contacter la Galerie par courrier électronique à l'adresse suivante : info@ghostgalerie.com.</p>
            <br />
            <p style={{fontWeight:"bolder"}}>III.6 Cookies</p>
            <p>La Galerie est susceptible de créer un " cookie " (identifiant alphanumérique) sur le disque dur de l'ordinateur de l'internaute dans le seul but de reconnaître ledit internaute lors de ses visites ultérieures sur le Site. Seul l'émetteur d'un cookie est susceptible de lire ou de modifier les informations qui y sont contenues. La durée de conservation des informations relatives à la navigation de l'internaute, telles que fournies par le " cookie " du Site, ne pourra excéder un an. Il est rappelé que l'internaute peut s'opposer à l'enregistrement de cookies sur son disque dur en configurant son logiciel de navigation sur Internet (Internet Explorer, Mozilla Firefox, Opera, Safari, etc.). A cet effet, l'internaute est renvoyé au guide d'utilisation de son logiciel de navigation sur Internet (rubrique " Aide " de la barre d'outils du navigateur) ou peut obtenir toute information à ce sujet auprès de l'éditeur dudit logiciel.</p>
            <br /><br />
            <p style={{fontWeight:"bolder"}}>IV. RESPONSABILITÉ</p>
            <p>La Galerie s'engage à assurer au mieux de ses possibilités, l'exactitude et la mise à jour des informations diffusées sur le Site. Elle se réserve le droit de corriger le contenu à tout moment et sans préavis. Toutefois, la Galerie ne peut garantir l'exactitude, la précision et l'exhaustivité des informations mises à disposition sur le Site. Ces informations ne sauraient constituer une garantie ou un engagement de la part de Galerie à l'égard de l'internaute. En particulier, la responsabilité de la Galerie ne saurait être engagée pour : - pour tous dommages résultant d'une intrusion informatique d'un tiers ayant entraîné une modification des informations mises à disposition sur le site ; - et plus généralement de tous dommages, directs ou indirects, quelles qu'en soient les causes, origines, natures et conséquences, provoqués à raison de l'accès de quiconque au Site ou de l'impossibilité d'y accéder, de même que l'utilisation du Site et/ou de la Galerie mettra en oeuvre les moyens destinés à assurer la sécurité des fichiers générés à partir des données personnelles collectées sur le Site. Il est toutefois précisé que la Galerie ne maîtrise pas les risques liés au fonctionnement d'Internet et attire l'attention des internautes sur la présence d'éventuels risques en termes de confidentialité des données transmises sur ce réseau.</p>
            <br /><br />
            <p style={{fontWeight:"bolder"}}>V. MODIFICATIONS DES MENTIONS LEGALES</p>
            <p>La Galerie informe les internautes qui consultent le Site que les présentes mentions légales peuvent être modifiées à tout moment. Ces modifications seront publiées en ligne et seront réputées acceptées sans réserve par tout internaute accédant au Site après la mise en ligne desdites modifications.</p>
            <br /><br />
            <p style={{fontWeight:"bolder"}}>VI. LITIGES</p>
            <p>Les présentes conditions juridiques sont établies conformément au droit français et, en particulier, aux dispositions de la loi n° 2004-575 du 21 juin 2004 " pour la confiance dans l'économie numérique " et de la loi n° 78-17 du 6 janvier 1978 " Informatique et libertés ". Les juridictions françaises sont compétentes pour connaître de tout litige relatif à l'utilisation du Site.</p>
            <br /><br />
            <br /><br />
            <p style={{fontSize: "8px"}}>© GHOST galerie - 2018 - TOUS DROITS RÉSERVÉS | MENTIONS LÉGALES</p>
          </div>
      </div>
    </div>
  );
};

export default MentionsLegales;
